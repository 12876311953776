import { FaLinkedinIn, FaTelegram, FaTwitter } from "react-icons/fa";

export const SocialShare = [
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/ciprian-d-5188789b/",
  },
  { Social: <FaTwitter />, link: "https://twitter.com/cipi_draghici" },
  {
    Social: <FaTelegram />,
    link: "https://t.me/cipriandraghici",
  },
];
