import { Link } from "react-router-dom";
import { projects } from "../../../constants/projects";

export const Portfolio = () => {
  return (
    <>
      {projects.map((value, index) => (
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
          <div className="portfolio">
            <div className="thumbnail-inner">
              <div
                className="thumbnail"
                id={value.image}
                style={{ backgroundImage: `url(${value.image})` }}
              ></div>
            </div>
            <div className="content">
              <div className="inner">
                {/*<p>{value.category}</p>*/}
                <h4>
                  <a href={`/project-details/${value.id}`}>{value.title}</a>
                </h4>
                <div className="portfolio-button">
                  <a className="rn-btn" href={`/project-details/${value.id}`}>
                    View Details
                  </a>
                </div>
              </div>
            </div>
            <Link className="link-overlay" to={`/project-details/${value.id}`}></Link>
          </div>
        </div>
      ))}
    </>
  );
};
