import {ReactNode, useEffect} from "react";
import { withRouter } from 'react-router-dom';

const PageScrollTop = ({children}: {children: ReactNode}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return children;
};

// @ts-ignore
export default withRouter(PageScrollTop);