import { Helmet } from "react-helmet";

type PageHelmetType = {
  pageTitle: string;
  metadata?: {
    name: string;
    content: string;
  }[];
};

export const PageHelmet = ({ pageTitle, metadata }: PageHelmetType) => {
  return (
    <Helmet>
      <title>{pageTitle}</title>
      {metadata &&
        metadata.map(({ name, content }) => (
          <meta name={name} content={content} />
        ))}
    </Helmet>
  );
};
