export const projects = [
  {
    id: "1",
    image: "/assets/images/portfolio/bridge-1.png",
    category: "Development",
    title: "Web 3 Blockchain Bridge",
    details: {
      pageTitle: "MultiverX Bridge",
      pageSubTitle:
        "MultiversX Bridge - decentralized application built on MultiversX blockchain",
      inCollaborationWith: "In collaboration with",
      projectTitle: "MultiversX",
      projectSubTitle:
        "MultiversX Bridge is a decentralized application built on MultiversX blockchain that allows users to transfer tokens between different blockchains",
      description: [],
      photos: [
        "/assets/images/portfolio/bridge-2.png",
        "/assets/images/portfolio/bridge-3.png",
      ],
      videoImagePreview: "/assets/images/portfolio/bridge-1.png",
      videoId: "",

      branch: "Web3",
      projectType: "Web application",
      website: "https://bridge.multiversx.com/",
    },
  },
  {
    id: "2",
    image: "/assets/images/portfolio/exchange-1.png",
    category: "Development",
    title: "Web 3 Blockchain Exchange",
    details: {
      pageTitle: "xexchange.com",
      pageSubTitle:
        "xexchange.com is a decentralized exchange built on MultiversX blockchain",
      inCollaborationWith: "In collaboration with",
      projectTitle: "MultiversX",
      projectSubTitle:
        "xexchange.com is a decentralized exchange built on MultiversX blockchain",
      description: [],
      photos: [],
      videoImagePreview: "/assets/images/portfolio/exchange-1.png",
      videoId: "",

      branch: "Web3",
      projectType: "Web application",
      website: "https://xexchange.com/",
    },
  },
  {
    id: "3",
    image: "/assets/images/portfolio/i4Designer-1.jpeg",
    category: "Development",
    title: "HMI Builder",
    details: {
      pageTitle: "i4Designer",
      pageSubTitle:
        "Low-code platform to create HTML5 applications for i4HMI, i4SCADA, i4BACnet and i4connected visually and without programming knowledge.",
      inCollaborationWith: "In collaboration with",
      projectTitle: "WEBfactory GmbH",
      projectSubTitle:
        "WEBfactory's i4 products connect your machines, plants, buildings, and infrastructure with the digital world.",
      description: [
        "The i4 products process the collected data to obtain valuable information almost in real time. Important features are the visualisation and control of processes, but also the analysis and prediction of events.",
        "The WEBfactory i4 products are completely based on HTML5 and work with any browser, anywhere and on any device. Existing interfaces for all common protocols and automation devices enable simple integration into your infrastructure.",
        "The ready-made dashboards, libraries, and apps have a modern design and intuitive user interface. This enables you to set up the following applications and systems in the shortest possible time and adapt them to the individual requirements of your customers.",
      ],
      photos: [
        "/assets/images/portfolio/i4Designer-1.jpeg",
        "/assets/images/portfolio/i4Designer-2.webp",
        "/assets/images/portfolio/i4Designer-3.png",
      ],
      videoImagePreview: "/assets/images/portfolio/i4DesignerPreview.png",
      videoId: "7nzbEXsHrRU",

      branch: "IIoT",
      projectType: "Web application",
      website: "https://webfactory-i4.com/products/i4designer/",
    },
  },
  // {
  //   id: "4",
  //   image: "/assets/images/portfolio/mas-1.png",
  //   category: "Development",
  //   title: "maprogramez.ro",
  //   details: {
  //     pageTitle: "MaProgramez.RO",
  //     pageSubTitle:
  //       "It's a web based platform that allows users to easily search for and book appointments with a lot of providers in many areas, with a single account.",
  //     inCollaborationWith: "In collaboration with",
  //     projectTitle: "Team-CFC",
  //     projectSubTitle:
  //       "MaProgramez.RO is a user-friendly web based platform that allows users to easily search for and book appointments to a doctor, or for barbershop or hear-styling, all with only one account.",
  //     description: [
  //       "The product integrate many applications that allows users to manage theirs appointments or to check the appointments history.",
  //       "The product offers also specialized views for the some types of users like doctors, clinics, barbershop owners, barbershop employee, manager, etc, that allow them to manage theirs schedulers",
  //     ],
  //     photos: [
  //       "/assets/images/portfolio/mas-2.png",
  //       "/assets/images/portfolio/mas-3.png",
  //       "/assets/images/portfolio/mas-4.png",
  //     ],
  //     videoImagePreview: "/assets/images/portfolio/masPreview.png",
  //     videoId: "",
  //
  //     branch: "Appointments",
  //     projectType: "Web application",
  //     website: "https://maprogramez.ro/",
  //   },
  // },
  // {
  //   id: "5",
  //   image: "/assets/images/portfolio/ismile-preview.png",
  //   category: "Development",
  //   title: "iSmile RetroBooth",
  //   details: {
  //     pageTitle: "iSmile RetroBooth",
  //     pageSubTitle:
  //       "It's a web portfolio of a photography company specialized in weddings and corporate events",
  //     inCollaborationWith: "",
  //     projectTitle: "iSmile RetroBooth",
  //     projectSubTitle:
  //       "It's specialized in capturing the special moments of your events through professional photography. From weddings to corporate gatherings and everything in between, the team of experienced photographers will be there to document the memories you'll treasure for a lifetime",
  //     description: [
  //       "They pride themselves on theirs attention to detail and ability to tell the story of your event through our images. ",
  //       "With a focus on high-quality, visually stunning results, they'll work with you to create the perfect package to suit your needs.",
  //       "Some of theirs services:",
  //       "-  Charleston and destination wedding collections",
  //       "-  Family portrait sessions",
  //       "-  Headshot sessions",
  //       "-  Personal photo album",
  //     ],
  //     photos: [
  //       "/assets/images/portfolio/ismile-1.png",
  //       "/assets/images/portfolio/ismile-2.png",
  //       "/assets/images/portfolio/ismile-3.png",
  //       "/assets/images/portfolio/ismile-4.png",
  //       "/assets/images/portfolio/ismile-5.png",
  //       "/assets/images/portfolio/ismile-6.png",
  //     ],
  //     videoImagePreview: "/assets/images/portfolio/ismile-preview.png",
  //     videoId: "",
  //
  //     branch: "Social Events",
  //     projectType: "Web application",
  //     website: "http://ismile.cipriandraghici.ro/",
  //   },
  // },
];
