import {
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
  FiVoicemail,
} from "react-icons/fi";
import { AiFillMobile } from "react-icons/ai";
import { CgUnavailable } from "react-icons/cg";

export const Services = () => {
  const serviceList = [
    {
      icon: <FiCast />,
      disableIcon: <CgUnavailable />,
      title: "IT Consultancy",
      description:
        "Offer solutions to improve tech infrastructure, software development, data management & more to enhance business operations.",
      disabled: false,
    },
    {
      icon: <FiLayers />,
      disableIcon: <CgUnavailable />,
      title: "Web App Development",
      description:
        "Create user-friendly, responsive web applications to improve customer engagement & streamline business processes.",
      disabled: false,
    },
    {
      icon: <FiMonitor />,
      disableIcon: <CgUnavailable />,
      title: "UI/UX",
      description:
        "Improve user experience by creating intuitive & visually appealing interfaces for web applications.",
      // description: 'Improve user experience by creating intuitive & visually appealing interfaces for web & mobile apps, websites & software.',
      disabled: false,
    },
    {
      icon: <FiUsers />,
      disableIcon: <CgUnavailable />,
      title: "IT Management",
      description:
        "Ensures the efficient & effective use of technology to support & improve business operations, by planning, organizing & controlling IT resources.",
      disabled: false,
    },
    {
      icon: <FiVoicemail />,
      disableIcon: <CgUnavailable />,
      title: "Trainings",
      description:
        "Web development courses. Teach the skills necessary to design and develop professional, responsive and user-friendly web applications.",
      disabled: false,
    },
    {
      icon: <AiFillMobile />,
      disableIcon: <CgUnavailable />,
      title: "Mobile App Development",
      description: "Coming soon...",
      // description: 'Creates custom, interactive apps for smartphones & tablets to improve customer engagement & streamline business processes.',
      disabled: true,
    },
  ];

  return (
    <div className="row">
      {serviceList.map((val, i) => (
        <div
          className={"col-lg-4 col-md-6 col-sm-6 col-12 text-left"}
          key={i}
          aria-disabled={true}
        >
          <a
            href="/service-details"
            className={`service-details-link ${val.disabled ? "disabled" : ""}`}
            title={val.disabled ? "Temporary unavailable" : undefined}
          >
            <div className={`service service__style--2`}>
              <div className="icon">
                {val.disabled ? val.disableIcon : val.icon}
              </div>
              <div className="content">
                <h3 className="title">{val.title}</h3>
                <p>{val.description}</p>
              </div>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};
