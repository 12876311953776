import { FiX, FiMenu } from "react-icons/fi";
import { useEffect } from "react";
import Scrollspy from "react-scrollspy";
import { SocialShare } from "../constants/social";

type HeaderType = {
  homeLink?: string;
  color?: "color-white" | "color-black";
};

export const Header = ({
  color = "color-black",
  homeLink = "/",
}: HeaderType) => {
  const logoUrl = (
    <img
      className="standard-logo"
      src="/assets/images/logo/logo.png"
      alt="Ciprian Draghici"
    />
  );

  const menuTrigger = () => {
    document.querySelector(".header-wrapper")!.classList.toggle("menu-open");
  };

  const closeMenuTrigger = () => {
    document.querySelector(".header-wrapper")!.classList.remove("menu-open");
  };

  useEffect(() => {
    window.addEventListener("scroll", function () {
      const value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed")?.classList.add("sticky");
      } else {
        document.querySelector(".header--fixed")?.classList.remove("sticky");
      }
    });

    const elements = document.querySelectorAll(".has-droupdown > a")!;
    for (const i in elements) {
      if (elements.hasOwnProperty(i)) {
        // @ts-ignore
        elements[i].onclick = function () {
          this.parentElement!.querySelector(".submenu")!.classList.toggle(
            "active"
          );
          this.classList.toggle("open");
        };
      }
    }
  }, []);

  return (
    <header className={`header-area header-style-two header--fixed ${color}`}>
      <div className="header-wrapper">
        <div className="header-left d-flex align-items-center">
          <div className="logo">
            <a href={homeLink}>{logoUrl}</a>
          </div>
          <nav className="mainmenunav d-lg-block ml--50">
            <Scrollspy
              className="mainmenu"
              items={["home", "about", "service", "portfolio", "contact"]}
              currentClassName="is-current"
              offset={-200}
            >
              <li>
                <a href="/#home">Home</a>
              </li>
              <li>
                <a href="/#about">About</a>
              </li>
              <li>
                <a href="/#service">Services</a>
              </li>
              <li>
                <a href="/#portfolio">Portfolio</a>
              </li>
              <li>
                <a href="/#contact">Contact</a>
              </li>
            </Scrollspy>
          </nav>
        </div>
        <div className="header-right">
          <div className="social-share-inner">
            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
              {SocialShare.map((val, i) => (
                <li key={i}>
                  <a href={`${val.link}`}>{val.Social}</a>
                </li>
              ))}
            </ul>
          </div>
          {/* Start Humberger Menu  */}
          <div className="humberger-menu d-block d-lg-none pl--20">
            <span onClick={menuTrigger} className="menutrigger text-white">
              <FiMenu />
            </span>
          </div>
          {/* End Humberger Menu  */}
          <div className="close-menu d-block d-lg-none">
            <span onClick={closeMenuTrigger} className="closeTrigger">
              <FiX />
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};
